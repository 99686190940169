<template>
  <div>
    <s-header :name="'支付'"></s-header>
    <div class="top">
      <p>支付金额</p>
      <p style="font-size: 20px;color: #333">￥{{ payNum }}</p>
    </div>
    <div class="info">
      <div style="display: flex;align-items: center">
        <van-icon class="iconPay" name="wechat-pay"/>
        <div class="tip">
          <p>微信支付</p>
          <p style="font-size: 14px;color: #999">推荐使用微信支付</p>
        </div>
      </div>
      <van-radio checked-color="#36cb59"></van-radio>
    </div>
    <van-button class="payBtn" @click="show = true">确认支付</van-button>
    <van-dialog v-model:show="show" title="支付成功">
      <div></div>
    </van-dialog>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import {useRouter} from "vue-router";
import {orderPayNow} from "../../../service/order";
import {ref, onMounted} from "vue";

export default {
  components: {
    sHeader
  },
  name: "pay",
  setup() {
    const show = ref(false)
    const router = useRouter()
    const payNum = ref(0)
    onMounted(async () => {
      const val = {
        simplify: 1,
        id: router.currentRoute.value.query.ids,
        coupon_id: router.currentRoute.value.query.coupon_id
      }
      const {data} = await orderPayNow(val)
      payNum.value = data.pay_money
    })
    return {
      show,
      payNum
    }
  }
}
</script>

<style scoped lang="less">
.top {
  margin: 20px auto;
  text-align: center;
  font-size: 18px;
  color: #999;
}
::v-deep .van-popup--center{
  width: 80%;
}
.info {
  padding: 20px 20px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;

  .iconPay {
    font-size: 30px;
    color: #36cb59;
    margin-right: 10px;
  }

  .tip {
    font-size: 16px;
  }
}

.payBtn {
  width: 92%;
  margin: 40px 4%;
  background: #fa436a;
  color: #fff;
}
</style>
